const state = () => ({
  scrollPosition: 0,
  csrfToken: "",
  siteUrl: "",
  currentUser: 0,
  categorySelectors: [],
  trialSignup: false,
  activeSubscription: 0,
  subscription: "",
});

// Getters
const getters = {
  scrollPosition: (state, getters) => {
    return state.scrollPosition;
  },
};

// Actions
const actions = {};

// Mutations
const mutations = {
  setCsrfToken(state, token) {
    state.csrfToken = token;
  },
  setSiteUrl(state, value) {
    state.siteUrl = value;
  },
  setCurrentUser(state, value) {
    state.currentUser = value;
  },
  setActiveSubscription(state, value) {
    state.activeSubscription = value;
  },
  setSubscription(state, value) {
    state.subscription = value;
  },
  setCategorySelectors(state, value) {
    state.categorySelectors = value;
  },
  setTrialSignup(state, value) {
    state.trialSignup = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
