const state = () => ({
  cart: {},
});

// Getters
const getters = {};

// Actions
const actions = {
  updateCart({ commit, state }, cart) {
    commit("setCart", cart);
  },
};

// Mutations
const mutations = {
  setCart(state, value) {
    state.cart = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
