import { createStore, createLogger } from "vuex";
import VuexPersistence from "vuex-persist";

//Modules
import app from "./modules/app";
import course from "./modules/course";
import cart from "./modules/cart";

const debug = process.env.NODE_ENV !== "production";

const vuexLocal = new VuexPersistence<RootState>({
  storage: window.sessionStorage,
});

export default createStore({
  modules: {
    app,
    course,
    cart,
  },
  strict: debug,
  plugins: debug ? [createLogger(), vuexLocal.plugin] : [vuexLocal.plugin],
});
