const state = () => ({
  favorites: [],
  complete: [],
});

// Getters
const getters = {};

// Actions
const actions = {};

// Mutations
const mutations = {
  setFavorites(state, token) {
    state.favorites = token;
  },
  setComplete(state, value) {
    state.complete = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
