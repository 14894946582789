// Import
import { createApp, defineAsyncComponent } from "vue";

import axios from "axios";
// Components
import VideoBackground from 'vue-responsive-video-background-player'
// import YouTube from 'vue3-youtube'
import store from "./store";
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation, Pagination, EffectFade } from 'swiper/core';
import VueClickAway from "vue3-click-away";
import VueCookies from 'vue3-cookies';

import 'vue-slider-component/theme/antd.css'

// Styles
import 'swiper/swiper.scss';
// import 'swiper/swiper-bundle.css'

// Declare
declare const window: any;

// App main
const main = async () => {
    const app = createApp({
        delimiters: ["${", "}"],
        setup() {
          return {
            modules: [Navigation, Pagination],
          };
        },
        components: {
          // Site
          SiteMenu: defineAsyncComponent(() =>import(/* webpackChunkName: "SiteMenu" */ "@/js/components/SiteMenu.vue")),
          Tabs: defineAsyncComponent(() =>import(/* webpackChunkName: "Tabs" */ "@/js/components/Tabs.vue")),
          Tab: defineAsyncComponent(() =>import(/* webpackChunkName: "Tab" */ "@/js/components/objects/Tab.vue")),
          Search: defineAsyncComponent(() =>import(/* webpackChunkName: "Search" */ "@/js/components/Search.vue")),
          OverlayVideo: defineAsyncComponent(() =>import(/* webpackChunkName: "OverlayVideo" */ "@/js/components/OverlayVideo.vue")),
          CCategory: defineAsyncComponent(() =>import(/* webpackChunkName: "Category" */ "@/js/components/Category.vue")),
          CategoryGrid: defineAsyncComponent(() =>import(/* webpackChunkName: "CategoryGrid" */ "@/js/components/CategoryGrid.vue")),
          CategorySelectors: defineAsyncComponent(() =>import(/* webpackChunkName: "CategorySelectors" */ "@/js/components/category/Selectors.vue")),
          Newsletter: defineAsyncComponent(() =>import(/* webpackChunkName: "Newsletter" */ "@/js/components/Newsletter.vue")),
          Signup: defineAsyncComponent(() =>import(/* webpackChunkName: "Signup" */ "@/js/components/Signup.vue")),

          // Account
          AccountLogin: defineAsyncComponent(() =>import(/* webpackChunkName: "AccountLogin" */ "@/js/components/account/AccountLogin.vue")),
          AccountDetails: defineAsyncComponent(() =>import(/* webpackChunkName: "AccountDetails" */ "@/js/components/account/AccountDetails.vue")),
          AccountDropdown: defineAsyncComponent(() =>import(/* webpackChunkName: "AccountDropdown" */ "@/js/components/account/AccountDropdown.vue")),

          // Commerce
          CommerceAddToCart: defineAsyncComponent(() =>import(/* webpackChunkName: "CommerceAddToCart" */ "@/js/components/commerce/AddToCart.vue")),
          CommerceCheckout: defineAsyncComponent(() =>import(/* webpackChunkName: "CommerceCheckout" */ "@/js/components/commerce/Checkout.vue")),
          CommerceLineitems: defineAsyncComponent(() =>import(/* webpackChunkName: "CommerceLineitems" */ "@/js/components/commerce/Lineitems.vue")),
          CommerceSummery: defineAsyncComponent(() =>import(/* webpackChunkName: "CommerceSummery" */ "@/js/components/commerce/Summery.vue")),

          // Blocks
          BlockForm: defineAsyncComponent(() =>import(/* webpackChunkName: "BlockForm" */ "@/js/components/blocks/Form.vue")),
          BlockCourseSlider: defineAsyncComponent(() =>import(/* webpackChunkName: "BlockCourseSlider" */ "@/js/components/blocks/CourseSlider.vue")),
          BlockVideo: defineAsyncComponent(() =>import(/* webpackChunkName: "BlockVideo" */ "@/js/components/blocks/Video.vue")),

          // Course
          CCourse: defineAsyncComponent(() =>import(/* webpackChunkName: "CourseVideo" */ "@/js/components/Course.vue")),
          Comments: defineAsyncComponent(() =>import(/* webpackChunkName: "Comments" */ "@/js/components/course/Comments.vue")),
          CourseFavorite: defineAsyncComponent(() =>import(/* webpackChunkName: "Favorite" */ "@/js/components/course/Favorite.vue")),
          CourseChart: defineAsyncComponent(() =>import(/* webpackChunkName: "Chart" */ "@/js/components/course/Chart.vue")),
          CourseComplete: defineAsyncComponent(() =>import(/* webpackChunkName: "Complete" */ "@/js/components/course/Complete.vue")),
          CourseAccordion: defineAsyncComponent(() =>import(/* webpackChunkName: "CourseAccordion" */ "@/js/components/course/Accordion.vue")),
          CourseOverview: defineAsyncComponent(() =>import(/* webpackChunkName: "CourseOverview" */ "@/js/components/objects/Overview.vue")),
          CourseTrialSignup: defineAsyncComponent(() =>import(/* webpackChunkName: "CourseTrialSignup" */ "@/js/components/course/TrialSignup.vue")),

          //Guide
          GuideGrid: defineAsyncComponent(() =>import(/* webpackChunkName: "GuideGrid" */ "@/js/components/GuideGrid.vue")),


          //Objects
          ObjectGiftcards: defineAsyncComponent(() =>import(/* webpackChunkName: "ObjectGiftcards" */ "@/js/components/objects/Giftcards.vue")),
          ObjectSharePopup: defineAsyncComponent(() =>import(/* webpackChunkName: "ObjectSharePopup" */ "@/js/components/objects/SharePopup.vue")),


        },
        data: () => ({
            scroll_position: 0,
            scroll_direction: '',
        }),
        methods: {
            handleScroll: function (event: any) {
                this.scroll_direction = document.documentElement.scrollTop > this.scroll_position ? "down" : "up";
                this.scroll_position = document.documentElement.scrollTop;

                if (this.scroll_direction === "down") {
                    document.body.classList.add("scrolled");
                    document.getElementById('p-header')?.classList.add("p-header--scrolled");
                }
                if (this.scroll_direction === "up") {
                    document.body.classList.remove("scrolled");
                    if(this.scroll_position < 30){
                      document.getElementById('p-header')?.classList.remove("p-header--scrolled");
                    }
                }
            },
        },
        created() {
            window.addEventListener("scroll", this.handleScroll, {
                passive: true,
            });
        },
        destroyed() {
            window.removeEventListener("scroll", this.handleScroll);
        },
        beforeMount() {
            //Get sitedata
            store.commit("app/setSiteUrl", window.siteData.siteUrl);
            store.commit("app/setCsrfToken", window.siteData.csrf);
            store.commit("app/setCurrentUser", parseInt(window.siteData.currentUser));
            store.commit("app/setActiveSubscription", parseInt(window.siteData.activeSubscription));
            store.commit("app/setTrialSignup", false);

            axios.get("/api/qd/get-site-data").then((res) => {
              store.commit('course/setFavorites', res.data.favorites)
              store.commit('course/setComplete', res.data.complete)
              store.commit('app/setSubscription', res.data.subscription)
              store.dispatch('cart/updateCart', res.data.cart)
            })
        },
    });

    SwiperCore.use([Navigation, Pagination, EffectFade]);

    app.use(store);
    app.use(VueClickAway)
    app.use(VueCookies);

    // Component liberaries
    app.component('video-background', VideoBackground);
    // app.component('YouTube', YouTube);
    app.component('Swiper', Swiper);
    app.component('SwiperSlide', SwiperSlide);

    // Mount the app
    const vm = app.mount("#app");
    return vm;
};

// Execute async function
main().then((vm) => {});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
